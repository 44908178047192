require('./public/Theme/plugins/pace/pace.js')
// require('./node_modules/angular/angular.min.js')
require('./node_modules/angular/angular.js')
require('./node_modules/angular-touch/angular-touch.js')
require('./public/Theme/plugins/jquery/jquery-1.9.1.min.js')
require('./public/Theme/plugins/jquery/jquery-migrate-1.1.0.min.js')
// require('./public/Theme/plugins/jquery-ui/ui/minified/jquery-ui.min.js')
require('./public/Theme/plugins/jquery-ui/ui/jquery-ui.js')
require('./node_modules/jquery-ui-touch-punch/jquery.ui.touch-punch.js')
require('./public/Theme/plugins/gritter/js/jquery.gritter.js')
require('./public/Theme/plugins/slimscroll/jquery.slimscroll.min.js')
// require('./public/Theme/plugins/jquery-cookie/jquery.cookie.js')
require('./node_modules/angular-ui-bootstrap/dist/ui-bootstrap-tpls.js')
require('./public/Theme/plugins/angularjs/ocLazyLoad.min.js')
require('./node_modules/@uirouter/angularjs/release/angular-ui-router.js')
require('./node_modules/angular-i18n/angular-locale_es-mx.js')
require('./node_modules/ui-select/dist/select.min.js')
require('./node_modules/angular-sanitize/angular-sanitize.min.js')
require('./node_modules/angular-aria/angular-aria.js')
// require('./node_modules/angular-animate/angular-animate.js')
require('./node_modules/lodash/lodash.js')
import * as iziToast from './node_modules/izitoast/dist/js/iziToast.js'
window.iziToast = iziToast
require('./public/Theme/plugins/angular-dragdrop/src/angular-dragdrop.js')
require('./node_modules/angular-cookies/angular-cookies.js')
require('./node_modules/ios-dblclick/ios-dblclick.js')
require('./node_modules/satellizer/satellizer.js')
require('./node_modules/angular-md5/angular-md5.js')

//vendor css
import './public/Theme/plugins/jquery-ui/themes/base/minified/jquery-ui.min.css'
import './public/Theme/css/animate.min.css'
import './public/Theme/css/style.min.css'
import './public/Theme/css/style-responsive.min.css'
import './public/Theme/css/theme/default.css'
import './public/Theme/plugins/gritter/css/jquery.gritter.css'
import './node_modules/izitoast/dist/css/iziToast.min.css'
import './node_modules/ui-select/dist/select.min.css'
